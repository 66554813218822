/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState) {
  const { userInfo } = initialState ?? {};
  return {
    // 页面
    showUserListPage: [0, 1].includes(userInfo?.parent_id),
    showDeviceCodeManagePage: userInfo?.id == 1,
    // 按钮
    // 创建激活码按钮
    createActiveCodeBtn: userInfo?.id != 1,
    // 解锁、锁定设备
    lockDeviceBtn: userInfo?.id == 1,
  };
}
