/**
 * 字典转数组
 * @param {Object} obj
 */
const dict2Array = (obj, value = 'value', label = 'label') => {
  const arr = [];
  Object.keys(obj).map((key) => {
    arr.push({
      [value]: key,
      [label]: obj[key],
    });
  });
  return arr;
};

const JSONparse = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return str;
  }
};

const JSONstringify = (obj) => {
  try {
    return JSON.stringify(obj);
  } catch (err) {
    return obj;
  }
};

const getChildren = (arr, key = 'id', rootId = 0) => {
  const res = [];
  for (const item of arr) {
    if (item.parent_id === rootId) {
      res.push({ ...item, children: getChildren(arr, item[key]) });
    }
  }
  return res;
};

export { dict2Array, JSONparse, JSONstringify, getChildren };
