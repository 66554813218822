// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/lyy/Documents/project/car64/car-perfume-web/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login",
        "layout": false,
        "routes": [
          {
            "path": "/login",
            "routes": [
              {
                "name": "login",
                "path": "/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/user",
        "name": "渠道商管理",
        "icon": "user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/User'), loading: LoadingComponent}),
        "access": "showUserListPage",
        "exact": true
      },
      {
        "path": "/active-code",
        "name": "激活码管理",
        "icon": "key",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ActiveCode' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/ActiveCode'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/perfume",
        "name": "香水管理",
        "icon": "key",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Perfume' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/Perfume'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/device/:type",
        "name": "序列号管理",
        "icon": "field-number",
        "access": "showDeviceCodeManagePage",
        "routes": [
          {
            "path": "/device/1",
            "name": "设备序列号",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceCode' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/DeviceCode'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/device/2",
            "name": "香水序列号",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DeviceCode' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/DeviceCode'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dashboard",
        "name": "数据统计",
        "icon": "area-chart",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/Dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/Users/lyy/Documents/project/car64/car-perfume-web/src/pages/Dashboard'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
