import { getLevelOneList } from '../service'

const userModel = {
  namespace: 'userPage',
  state: {
    // 一级渠道商列表
    levelOneListData: [],
  },
  effects: {
    // 获取用户列表
    *getLevelOneList({ payload }, { call, put, select }) {
      const { data, status } = yield call(getLevelOneList);
      if (status) {
        yield put({
          type: 'saveData',
          payload: {
            levelOneListData: data || [],
          },
        });
      }
    },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default userModel;
