const deviceCodeModel = {
  namespace: 'deviceCodePage',
  state: {
    type: 1,
  },
  effects: {
    // 获取统计数据
    // *getList({ payload }, { call, put, select }) {
    //   const { formValues, type } = yield select((state) => state.deviceCodePage);
    //   const { data, status } = yield call(getList, {
    //     ...formValues,
    //     type,
    //   });
    //   if (status) {
    //     yield put({
    //       type: 'saveData',
    //       payload: {
    //         dataList: data,
    //       },
    //     });
    //   } else {
    //     message.error('获取数据失败');
    //   }
    // },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default deviceCodeModel;
