import moment from 'moment';

class TimeTool {
  /**
   * 获取格式化日期(字符串)
   */
  formatDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(date || moment()).format(format);
  }

  /**
   * 获取日期对象
   */
  getTime(options = {}) {
    const { subtractInfo, addInfo } = options;
    let result = moment();
    if (subtractInfo) result = result.subtract(subtractInfo[0], subtractInfo[1]);
    if (addInfo) result = result.add(addInfo[0], addInfo[1]);
    return result;
  }

  /**
   * 获取时间戳
   */
  getTimeStamp(time = moment()) {
    return moment(time).valueOf();
  }

  /**
   * 日期字符串转日期对象
   */
  string2DateObject(str, format = 'YYYY-MM-DD HH:mm:ss') {
    if (str) return moment(str, format);
    return null;
  }
}

export default new TimeTool();
