import { message } from 'antd';
import timeTools from '@/utils/time';

import { getDashboard } from '../service';

const dashboardModel = {
  namespace: 'dashboardPage',
  state: {
    formValues: {
      dateType: 1,
      startDate: `${timeTools.formatDate(null, 'YYYY-MM-DD')} 00:00:00`,
      endDate: `${timeTools.formatDate(null, 'YYYY-MM-DD')} 23:59:59`,
    },
    saleData: {},
    pageLoading: false,
  },
  effects: {
    // 获取统计数据
    *getDashboard({ payload }, { call, put, select }) {
      yield put({ type: 'saveData', payload: { pageLoading: true } });
      const { formValues } = yield select((state) => state.dashboardPage);
      const { data, status } = yield call(getDashboard, {
        startDate: formValues.startDate,
        endDate: formValues.endDate,
      });
      if (status) {
        yield put({
          type: 'saveData',
          payload: {
            saleData: data,
          },
        });
      } else {
        message.error('获取数据失败');
      }
      yield put({ type: 'saveData', payload: { pageLoading: false } });
    },
  },
  reducers: {
    saveData(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default dashboardModel;
