// @ts-nocheck

  import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import KeyOutlined from '@ant-design/icons/es/icons/KeyOutlined';
import FieldNumberOutlined from '@ant-design/icons/es/icons/FieldNumberOutlined';
import AreaChartOutlined from '@ant-design/icons/es/icons/AreaChartOutlined'
  export default {
    UserOutlined,
KeyOutlined,
FieldNumberOutlined,
AreaChartOutlined
  }