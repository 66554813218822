import request from '@/utils/request';

/**
 * 查询渠道商列表
 */
export const getUserList = async (params) => request('/user/list', { params });


/**
 * 添加渠道商
 */
 export const addUser = async (params) =>
  request.post(`/user/add`, {
    data: params,
    headers: { 'Content-Type': 'application/json' },
  });

/**
 * 修改渠道商信息
 */
 export const eidtUser = async (params) =>
  request.post(`/user/edit`, {
    data: params,
    headers: { 'Content-Type': 'application/json' },
  });

/**
 * 删除渠道商
 */
 export const deleteUser = async (params) => request('/user/delete', { params });

 /**
 * 重置渠道商密码
 */
export const resetPsd = async (params) =>
request.post(`/user/reset-psd`, {
  data: params,
  headers: { 'Content-Type': 'application/json' },
});

/**
 * 修改密码
 * @returns 
 */
export const updatePsd = async (params) =>
request.post(`/user/update-psd`, {
  data: params,
  headers: { 'Content-Type': 'application/json' },
});

/**
 * 查询所有一级代理
 */
 export const getLevelOneList = async (params) => request('/user/level-one');