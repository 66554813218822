/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';

import CONST from '@/utils/const';
import { cookieTool } from '@/utils/web-storage.js';

/**
 * 异常处理程序
 */
const errorHandler = () => {};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  prefix: '/api',
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
  // headers: {
  //   authorization: cookieTool.get('token')
  // }
});

// request拦截器, 改变url 或 options.
request.interceptors.request.use((url, options) => {
  const { headers } = options;
  console.log('----------url:', url);
  return {
    url,
    options: {
      ...options,
      headers: {
        ...headers,
        authorization: cookieTool.get('token'),
      },
    },
  };
});

// 对于http状态码是200，但是返回response是错误的处理
request.interceptors.response.use(async (response) => {
  if (response.status >= 200 && response.status < 300) {
    const res = await response.clone().json();
    if ([CONST.statusCode.notLogin, CONST.statusCode.tokenError].includes(res.code))
      window.location.hash = '/login';
    return res;
  } else return { status: false };
});

export default request;
