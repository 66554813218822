import request from '@/utils/request';

/**
 * 查询销售数据
 */
export const getDashboard = async (params) =>
  request.post(`/summary/data`, {
    data: params,
    headers: { 'Content-Type': 'application/json' },
  });

// 测试用
export const createTestPerfume = async (params) => request('/create/perfume');
