import Cookies from 'js-cookie';
import { JSONstringify, JSONparse } from './utils';

class CookieTool {
  set(key, value, opt = {}) {
    Cookies.set(key, JSONstringify(value), opt);
  }

  get(key, opt = {}) {
    return JSONparse(Cookies.get(key, opt));
  }

  remove(key, opt = {}) {
    Cookies.remove(key, opt);
  }
}

export const cookieTool = new CookieTool();
