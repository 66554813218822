// @ts-nocheck

import UserOutlined from '@ant-design/icons/UserOutlined';
import KeyOutlined from '@ant-design/icons/KeyOutlined';
import FieldNumberOutlined from '@ant-design/icons/FieldNumberOutlined';
import AreaChartOutlined from '@ant-design/icons/AreaChartOutlined'

export default {
  UserOutlined,
KeyOutlined,
FieldNumberOutlined,
AreaChartOutlined
}
    