import { Dropdown, Menu, message } from 'antd';
import { history } from 'umi';
import { LogoutOutlined, EditOutlined } from '@ant-design/icons';

import styles from './index.less';
import EditPsd from './EditPsd';

import { cookieTool } from '@/utils/web-storage.js';
import { logout as logoutServe } from '../pages/User/service';

export default function Index(props) {
  const { userInfo } = props?.initialState || {};

  // 退出登录
  const logout = async () => {
    cookieTool.remove('token');
    history.push('/login');
  };

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
            <a style={{ display: 'block', width: '70px' }}>退出登录</a>
          </Menu.Item>
          <Menu.Item key="edit-psd" icon={<EditOutlined />}>
            <EditPsd reFresh={logout} />
          </Menu.Item>
        </Menu>
      }
    >
      <div className={styles.wrapper}>
        <img src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png" />
        <span>{userInfo?.name}</span>
      </div>
    </Dropdown>
  );
}
