import { Button, message } from 'antd';
import md5 from 'md5'
import {
  ModalForm,
  ProFormText,
  ProFormSelect,
  ProFormTextArea,
} from '@ant-design/pro-form';

import { updatePsd } from '../pages/User/service'


const EditPsd = ({ reFresh }) => {

  const modalProps = {
    title: '修改密码',
    trigger: (<a>修改密码</a>),
    modalProps: {
      onCancel: () => {}
    },
    onFinish: async (values) => {
      const { oldPsd, newPsd, confirmPsd } = values
      if (newPsd !== confirmPsd) {
        message.error('新密码与确认密码不一致，请重新输入!')
        return false
      }
      const { status, msg } = await updatePsd({
        oldPsd: md5(oldPsd),
        newPsd: md5(newPsd)
      })
      if (status) {
        message.success('修改成功, 即将重新登录')
        setTimeout(() => {
          reFresh && reFresh()
        }, 1500);
      } else {
        message.error(msg || '修改失败')
      }
      return status
    }
  }

  return(
    <ModalForm {...modalProps}>
      <ProFormText.Password
        name="oldPsd"
        label="旧密码"
        placeholder="请输入旧密码"
        rules={[
          {
            required: true,
            message: '旧密码不能为空'
          }
        ]}
      />
      <ProFormText.Password
        name="newPsd"
        label="新密码"
        placeholder="请输入新密码"
        rules={[
          {
            required: true,
            message: '新密码不能为空'
          }
        ]}
      />
      <ProFormText.Password
        name="confirmPsd"
        label="确认新密码"
        placeholder="请再次输入新密码"
        rules={[
          {
            required: true,
            message: '确认新密码不能为空'
          }
        ]}
      />
    </ModalForm>
  )
}

export default EditPsd