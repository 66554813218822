import HeaderUser from './layout/index';

import request from '@/utils/request';
import { cookieTool } from '@/utils/web-storage.js';

const token = cookieTool.get('token');

const redirectToLogin = () => (window.location.hash = '/login');

if (!token && window.location.pathname !== '/login') redirectToLogin();

/*
  获取登录用户信息
*/
const getUserInfo = async () => {
  const { status, data } = await request('/user/current');
  if (!status) redirectToLogin();
  return data;
};

/*
  获取所有下级代理
*/
const getLowerLevel = async () => {
  const { status, data } = await request('/user/lowerlevel');
  if (status) {
    const userMap = {};
    data.map((item) => {
      userMap[item.id] = item;
    });
    return userMap;
  }
  return {};
};

export async function getInitialState() {
  if (window.location.pathname !== '/login') {
    const userInfo = await getUserInfo();
    const userMap = await getLowerLevel();
    return {
      userInfo,
      userMap,
    };
  }
}

export const layout = ({ initialState, setInitialState }) => {
  return {
    rightContentRender: () => <HeaderUser initialState={initialState} />,
  };
};
